import { Icon, Flex, Text, useColorModeValue } from '@chakra-ui/react';

export function ItemContent(props: { title: string, message: string, icon: JSX.Element }) {
  const textColor = useColorModeValue('navy.700', 'white');
  return (
    <>
      <Flex
        justify="center"
        align="center"
        borderRadius="16px"
        minH={{ base: '60px', md: '70px' }}
        h={{ base: '60px', md: '70px' }}
        minW={{ base: '60px', md: '70px' }}
        w={{ base: '60px', md: '70px' }}
        me="14px"
        bgGradient="linear(to-b, brand.400, brand.600)"
      >
        <Icon as={props.icon.type} fontSize={'40px'}/>
      </Flex>
      <Flex flexDirection="column">
        <Text
          mb="5px"
          fontWeight="bold"
          color={textColor}
          fontSize={{ base: 'md', md: 'md' }}
        >
          {props.title}
        </Text>
        <Flex alignItems="center">
          <Text
            fontSize={{ base: 'sm', md: 'sm' }}
            lineHeight="100%"
            color={textColor}
          >
            {props.message}
          </Text>
        </Flex>
      </Flex>
    </>
  );
}
