import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';

import SearchTableUsers from './components/SearchTableUsersOverview';
import tableDataUsersOverview from './variables/tableDataUsersOverview';

export default function UsersOverview() {
	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Card px='0px'>
				<SearchTableUsers tableData={tableDataUsersOverview}  />
			</Card>
		</Flex>
	);
}
