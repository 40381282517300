import { Box, SimpleGrid, Flex } from "@chakra-ui/react";
import BulkActions from "./components/bulkactions";

export default function BulkActionsPage() {
	// Chakra Color Mode
	return (
    <Flex direction={{ base: 'column', xl: 'row' }}>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
          <BulkActions />
        </SimpleGrid>
      </Box>
    </Flex>
	);
}