import { Flex, Grid, useColorModeValue } from '@chakra-ui/react';
import { VSeparator } from 'components/separator/Separator';
import StockValue from 'views/admin/dashboards/default/components/StockValue';
import LowStock from 'views/admin/dashboards/default/components/LowStock';
import Items from 'views/admin/dashboards/default/components/Items';
import ActionBar from 'components/actions/ActionBar';
import { MdAdd, MdEdit, MdSearch } from 'react-icons/md';


export default function Default() {
  const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');

  const buttons = [
    {
      name: "Add Item",
      action: () => console.log("Add item clicked"),
      icon: MdAdd,
    },
    {
      name: "Adjust Stock Levels",
      action: () => console.log("Edit item clicked"),
      icon: MdEdit,
    },
    {
      name: "Search Inventory",
      action: () => console.log("Delete item clicked"),
      icon: MdSearch,
    },
  ]

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      
      <Flex direction="column" width="stretch">
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)', '2xl': '720fr 350fr' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex gridArea={{ base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}>
            <StockValue />
          </Flex>
          <Flex gridArea={{ base: '2 / 1 / 3 / 3', '2xl': '1 / 2 / 2 / 3' }}>
            <Items />
          </Flex>
        </Grid>
        <Flex direction="column" width="stretch">
          <Grid
            templateColumns="repeat(3, 1fr)" 
            gap="20px"
            display="grid"
          ><ActionBar buttons={buttons} />
          </Grid>
        </Flex>
      </Flex>
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <LowStock
        maxW={{ base: '100%', xl: '400px' }}
        maxH={{ base: '100%', xl: '1170px', '2xl': '100%' }}
      />
    </Flex>
  );
}
