import { Box, Icon } from "@chakra-ui/react"
import QuickButton from "./QuickButton"

interface ButtonConfig {
    name: string;
    action: () => void;
    icon?: any;
  }

export default function ActionBar({ buttons }: { buttons: ButtonConfig[] }) {
    return (
<Box display="flex" gap="10px">
      {buttons.map((btn, i) => (
        <QuickButton
          key={i}
          name={btn.name}
          action={btn.action}
          icon={btn.icon ? <Icon as={btn.icon} w="20px" h="20px" /> : undefined}
        />
      ))}
    </Box>
      )
      };
