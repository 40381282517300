import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
  } from '@chakra-ui/react';
  import Card from 'components/card/Card';
  import InputField from 'components/fields/InputField';
  import SelectField from 'components/fields/SelectField'
  import DateField from 'components/fields/DateField';
  import React from 'react';
  export default function AddItemForm() {
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [activeBullets, setActiveBullets] = React.useState({
        item: true,
        location: false,
    });
    const [tabIndex, setTabIndex] = React.useState(0);
  
    return (
      <Flex
        direction="column"
        minH="90vh"
        align="center"
        pt={{ sm: '125px', lg: '75px' }}
        position="relative"
      >
        <Box
          h="45vh"
          bgGradient="linear(to-b, brand.400, brand.600)"
          position="absolute"
          w="100%"
          borderRadius="20px"
        />
  
        <Tabs
          index={tabIndex}
          onChange={(index) => {
              setTabIndex(index);
              setActiveBullets({
              item: index >= 0,
              location: index >= 1,
              });
          }}
          variant="unstyled"
          zIndex="0"
          mt={{ base: '80px', md: '10px' }}
          display="flex"
          flexDirection="column"
        >
          <TabList
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifySelf="center"
          >
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              w={{ sm: '120px', md: '230px', lg: '320px' }}
              onClick={() =>
                setActiveBullets({
                  item: true,
                  location: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.location ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '30px' },
                  top: {
                    sm: activeBullets.item ? '6px' : '4px',
                    md: null,
                  },
                  position: 'absolute',
                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.item ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.item ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.item ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Item
                </Text>
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              w={{ sm: '120px', md: '250px', lg: '290px' }}
              onClick={() =>
                setActiveBullets({
                  item: true,
                  location: true,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.location ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.location ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.location ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Location
                </Text>
              </Flex>
            </Tab>

          </TabList>
          <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Item Info
                </Text>
                <Flex direction="column" w="100%">
                  <Stack direction="column" spacing="20px">
                    <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                      <InputField
                        mb="0px"
                        id="first"
                        placeholder="eg. Spectrum A4 Transfer Paper"
                        label="Item Name"
                      />
                      <SelectField
                        mb="0px"
                        id="select"
                        placeholder="Select"
                        label="Category"
                        ><option>Cartridges</option><option>Transfer Paper</option><option>Miscellaneous</option></SelectField>
                      <InputField
                        mb="0px"
                        id="lowStockAmount"
                        placeholder="eg. 5"
                        label="Low Stock Threshold"
                      />
                      <InputField
                        mb="0px"
                        id="Supplier"
                        placeholder="eg. Killer Ink"
                        label="Supplier"
                      />
                      <InputField
                        mb="0px"
                        id="CodeSku"
                        placeholder="eg. 4030120241"
                        label="Item Code / SKU"
                      />
                      <InputField
                        mb="0px"
                        id="PurchasePrice"
                        placeholder="eg. £42.00"
                        label="Purchase Price"
                      />
                      <DateField
                        mb="0px"
                        id="expiryDate"
                        label="Expiry Date (if applicable)"
                        placeholder="Select Date"
                      />
                      <SelectField
                        mb="0px"
                        id="CustomerPurchaseable"
                        label="Usage Type"
                        placeholder="Select Usage Type">
                            <option>Internal Use Only</option>
                            <option>Available for Sale</option>
                            <option>Both Internal and Sale</option>
                        </SelectField>
                    </SimpleGrid>
                  </Stack>
                  <Flex justify="space-between" mt="24px">
                    <Button
                      variant="darkBrand"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      ms="auto"
                      onClick={()  => {
                        setTabIndex(tabIndex + 1);
                        setActiveBullets({
                          item: true,
                          location: true,
                        });
                      }}
                    >
                      Next
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </TabPanel>
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Location
                </Text>
                <Flex direction="column" w="100%">
                  <Stack direction="column" spacing="20px">
                    <InputField
                      id="StoreLocation"
                      placeholder="eg. Studio Stockroom"
                      label="Warehouse / Store Location"
                      mb="0px"
                    />
                    <InputField 
                      id="shelfNumber"
                      placeholder="Top Shelf"
                      label='Shelf for Item (if applicable)'
                      mb='0px'
                    />
                    <InputField
                      id="quantity"
                      placeholder="eg. 5"
                      label="Quantity in Location"
                      mb="0px"
                    />
                    
                  </Stack>
                  <Flex justify="space-between" mt="24px">
                    <Button
                      variant="light"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      onClick={() => {
                        setTabIndex(tabIndex - 1);
                        setActiveBullets({
                          item: true,
                          location: false,
                        });
                      }}
                    >
                      Prev
                    </Button>
                    <Button
                      variant="darkBrand"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                    >
                      Submit
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    );
  }