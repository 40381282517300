/* eslint-disable */

import {
  Box,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Stack,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  ColumnFiltersState,
  getFilteredRowModel,
  getPaginationRowModel
} from '@tanstack/react-table';
// Custom components
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import * as React from 'react';
// Icons
import {
  MdLocalPrintshop,
  MdMiscellaneousServices,
  MdDraw,
  MdMedicalServices,
  MdEdit,
  MdChevronRight,
  MdChevronLeft,
  MdLocalDrink,
  MdMedicalInformation,
  MdChair
} from 'react-icons/md';

type RowObj = {
  name: string;
  category: string;
  contactEmail: string;
  contactPhone: string;
  location: string;
};

// DebouncedInput Component for smoother search experience
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e: any) => setValue(e.target.value)}
      style={{ padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}
      placeholder="Search..."
    />
  );
}

const columnHelper = createColumnHelper<RowObj>();

export default function SupplierTable(props: { tableData: any; tableName: string }) {
  const { tableData, tableName } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [data, setData] = React.useState<RowObj[]>([]);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  
  // Pagination State
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5); // Items per page

  React.useEffect(() => {
    // Transform tableData into the structure expected by the table
    setData(tableData.map((row: RowObj) => ({ ...row })));
  }, [tableData]);

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const columns = [
    columnHelper.display({
      id: 'edit',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          EDIT
        </Text>
      ),
      cell: () => (
        <Icon as={MdEdit} w="20px" h="20px" cursor="pointer" color={textColor} />
      ),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          NAME
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('category', {
      id: 'category',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          CATEGORY
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Icon
            w="24px"
            h="24px"
            me="5px"
            color="grey.500"
            as={
              info.getValue() === 'Cartridges'
                ? MdDraw
                : info.getValue() === 'Transfer Paper'
                ? MdLocalPrintshop
                : info.getValue() === 'Miscellaneous'
                ? MdMiscellaneousServices
                : info.getValue() === 'Aftercare'
                ? MdMedicalServices
                : info.getValue() === 'Tattoo Ink'
                ? MdLocalDrink
                : info.getValue() === 'Needles'
                ? MdDraw
                : info.getValue() === 'Sterilization Supplies'
                ? MdMedicalInformation
                : info.getValue() === 'Furniture'
                ? MdChair
                : null
            }
          />
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('category', {
      id: 'quantity',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          CATEGORY
        </Text>
      ),
      cell: (quantity) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {quantity.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('contactEmail', {
      id: 'contactEmail',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          EMAIL
        </Text>
      ),
      cell: (contactEmail) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {contactEmail.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('location', {
      id: 'location',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          LOCATION
        </Text>
      ),
      cell: (location) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {location.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('contactPhone', {
      id: 'contactphone',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          PHONE
        </Text>
      ),
      cell: (contactPhone) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {contactPhone.getValue()}
        </Text>
      ),
    }),
  ];

  const pagination = React.useMemo(() => ({
    pageIndex,
    pageSize: 10,
  }), [pageIndex]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
      columnFilters,
      pagination,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: (updater) => {
      if (typeof updater === 'function') {
        const newPagination = updater({ pageIndex, pageSize: 5 });
        if (newPagination.pageIndex !== undefined) setPageIndex(newPagination.pageIndex);
      } else {
        if (updater.pageIndex !== undefined) setPageIndex(updater.pageIndex);
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(), // Enable filtering
    getPaginationRowModel: getPaginationRowModel(), // Enable pagination
  });

  // Create pages utility
  const createPages = (count: number) => {
    let arrPageCount = [];
    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }
    return arrPageCount;
  };

  const pageCount = table.getPageCount();

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      {/* Header */}
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          {tableName}
        </Text>
        <Menu />
      </Flex>

      {/* Search Input */}
      <Flex px="25px" py="10px" mb="0px" justifyContent="left" align="center">
        <DebouncedInput
          value={globalFilter ?? ''}
          onChange={(value) => setGlobalFilter(String(value))}
          placeholder="Search..."
          style={{ fontSize: '1.1rem', border: '0' }}
        />
      </Flex>

      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    pe="10px"
                    borderColor={borderColor}
                    cursor="pointer"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <Flex
                      justifyContent="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: '',
                        desc: '',
                      }[header.column.getIsSorted() as string] ?? null}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    borderColor="transparent"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Pagination Controls */}
      <Flex w="100%" justify="space-between" px="20px" pt="10px" pb="5px">
        <Text
          fontSize='sm'
          color='gray.500'
          fontWeight='normal'
          mb={{ sm: "24px", md: "0px" }}
        >
          Showing {table.getState().pagination.pageSize * table.getState().pagination.pageIndex + 1} to{" "}
          {table.getState().pagination.pageSize * (table.getState().pagination.pageIndex + 1) <= data.length
            ? table.getState().pagination.pageSize * (table.getState().pagination.pageIndex + 1)
            : data.length}{" "}
          of {data.length} entries
        </Text>

        <div className="flex items-center gap-2">
          <Stack direction='row' alignSelf='flex-end' spacing='4px' ms='auto'>
            <Button
              variant='no-effects'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              transition='all .5s ease'
              w='40px'
              h='40px'
              borderRadius='50%'
              bg='transparent'
              border='1px solid'
              borderColor={useColorModeValue("gray.200", "white")}
              display={
                table.getCanPreviousPage() ? "flex" : "none"
              }
              _hover={{
                bg: "whiteAlpha.100",
                opacity: "0.7",
              }}
            >
              <Icon as={MdChevronLeft} w='16px' h='16px' color={textColor} />
            </Button>
            {createPages(pageCount).map((pageNumber, index) => {
              return (
                <Button
                  variant='no-effects'
                  transition='all .5s ease'
                  onClick={() => table.setPageIndex(pageNumber - 1)}
                  w='40px'
                  h='40px'
                  borderRadius='50%'
                  bg={
                    pageNumber === table.getState().pagination.pageIndex + 1 ? brandColor : "transparent"
                  }
                  border={
                    pageNumber === table.getState().pagination.pageIndex + 1
                      ? "none"
                      : "1px solid lightgray"
                  }
                  _hover={
                    pageNumber === table.getState().pagination.pageIndex + 1
                      ? {
                          opacity: "0.7",
                        }
                      : {
                          bg: "whiteAlpha.100",
                        }
                  }
                  key={index}
                >
                  <Text
                    fontSize='sm'
                    color={pageNumber === table.getState().pagination.pageIndex + 1 ? "#fff" : textColor}
                  >
                    {pageNumber}
                  </Text>
                </Button>
              );
            })}
            <Button
              variant='no-effects'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              transition='all .5s ease'
              w='40px'
              h='40px'
              borderRadius='50%'
              bg='transparent'
              border='1px solid'
              borderColor={useColorModeValue("gray.200", "white")}
              display={table.getCanNextPage() ? "flex" : "none"}
              _hover={{
                bg: "whiteAlpha.100",
                opacity: "0.7",
              }}
            >
              <Icon as={MdChevronRight} w='16px' h='16px' color={textColor} />
            </Button>
          </Stack>
        </div>
      </Flex>
    </Card>
  );
}
