import { SimpleGrid } from "@chakra-ui/react";
import ItemTable from "components/dataDisplay/ItemTable";
import stockTableData from "./data/stockTableData";
import StockHeader from "./components/stockHeader";

export default function StockLevels() {
    // Chakra Color Mode
    return (
            <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
                <StockHeader />
                <ItemTable tableData={stockTableData} tableName="Stock"/>
            </SimpleGrid>
    );
}