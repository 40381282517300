import { Box, SimpleGrid, Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import StockMovement from "components/dataDisplay/StockMovement";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import React from "react";

type Movement = {
  date: string;
  sum: string;
  name: string;
  reason: string;
};

const allMovements: Movement[] = [
  { date: "Today, 16:36", sum: "-1", name: "Aftercare Gift Bag", reason: "Completed appointment" },
  { date: "Today, 14:40", sum: "-1", name: "Aftercare Gift Bag", reason: "Completed appointment" },
  { date: "Yesterday, 09:00", sum: "+10", name: "Lip Candy Healing Gloss", reason: "New Order" },
  { date: "Yesterday, 09:00", sum: "+10", name: "Yayo Bubblicious Tattoo Butter", reason: "New Order" },
  { date: "Yesterday, 09:00", sum: "+15", name: "Saniderm Bandages", reason: "New Order" },
  { date: "Yesterday, 09:00", sum: "+20", name: "Black Gift Bags", reason: "New Order" },
  { date: "Two days ago, 11:15", sum: "+5", name: "Dynamic Black Ink (1oz)", reason: "New Shipment" },
  { date: "Two days ago, 11:15", sum: "-2", name: "Cartridge Needles (Liner)", reason: "Used during appointment" },
  { date: "Three days ago, 13:00", sum: "+8", name: "Spirit Classic Transfer Paper", reason: "New Shipment" },
  { date: "Three days ago, 13:00", sum: "-3", name: "Rosa After Care Balm 10ml", reason: "Damaged - disposed" },
  { date: "Three days ago, 13:00", sum: "+10", name: "Green Soap (Gallon)", reason: "Restock from Supplier" },
  { date: "Last Week, 10:20", sum: "-4", name: "Cheyenne HAWK Cartridges Liner 0.30", reason: "Used during appointments" },
];

export default function StockMovementsTable() {

  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, ] = React.useState(10); 
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [filteredMovements, setFilteredMovements] = React.useState<Movement[]>(allMovements);

  React.useEffect(() => {
    const filtered = allMovements.filter((movement) =>
      movement.name.toLowerCase().includes(globalFilter.toLowerCase()) ||
      movement.reason.toLowerCase().includes(globalFilter.toLowerCase())
    );
    setFilteredMovements(filtered);
  }, [globalFilter]);

  const start = pageIndex * pageSize;
  const end = start + pageSize;
  const pageData = filteredMovements.slice(start, end);

  const pageCount = Math.ceil(filteredMovements.length / pageSize);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex mb="40px" justify="space-between" align="center">
            <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
            Recent Stock Movement
            </Text>
        </Flex>
      {/* Search bar to filter the stock movements */}
      <Box mb="20px">
        <SearchBar
          value={globalFilter}
          onChange={(e: any) => setGlobalFilter(e.target.value)}
          h="44px"
          w={{ lg: "390px" }}
          borderRadius="16px"
          placeholder="Search by name or reason..."
        />
      </Box>

      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {pageData.map((movement, index) => (
          <StockMovement
            key={index}
            date={movement.date}
            sum={movement.sum}
            name={movement.name}
            reason={movement.reason}
          />
        ))}
      </SimpleGrid>

      {/* Pagination Controls */}
      <Flex justify="space-between" align="center">
        <Text>
          Showing {filteredMovements.length === 0 ? 0 : start + 1} to{" "}
          {end > filteredMovements.length ? filteredMovements.length : end} of{" "}
          {filteredMovements.length} entries
        </Text>

        <Flex gap="10px">
          <Button
            onClick={() => setPageIndex(pageIndex - 1)}
            disabled={pageIndex === 0}
          >
            Prev
          </Button>
          <Button
           onClick={() => setPageIndex(pageIndex + 1)}
           disabled={pageIndex >= pageCount - 1 || pageCount === 0}
          >
            Next
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
