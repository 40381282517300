import { Box, Button } from "@chakra-ui/react"

export default function CategoriesMenu() {
    return (
      <Box>
      <Button
        variant="brand"
        fontSize="sm"
        borderRadius="16px"
        w={{ base: '128px', md: '148px' }}
        h="32px"
        ms="auto"
      >
        Add New
      </Button>
      <Button
        variant="brand"
        fontSize="sm"
        borderRadius="16px"
        w={{ base: '128px', md: '148px' }}
        h="32px"
        ms="15px"
      >
        Export
      </Button>
      </Box>
    )
};