import { Box, SimpleGrid } from "@chakra-ui/react";
import SupplierTable from "components/dataDisplay/SupplierTable";
import supplierTableData from "./data/supplierData";

export default function SuppliersPage() {
    return(
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <SimpleGrid
          gap="20px"
        >
            <SupplierTable tableData={supplierTableData} tableName="Suppliers" />
            </SimpleGrid>
        </Box>
    )
}