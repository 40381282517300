

type RowObj = {
	item: string;
	category: string;
	quantity: string;
	stockLevel: number;
	location: string;
	premises: string;
};

const stockTableData: RowObj[] = [
	{
		item: 'Post Appointment Bag',
		stockLevel: 100,
		quantity: '20',
		category: 'Aftercare',
		location: 'Reception cupboard',
		premises: 'Studio',
	},
	{
		item: 'Lush body butter',
		stockLevel: 75,
		quantity: '50',
		category: 'Aftercare',
		location: 'Reception cupboard',
		premises: 'Studio',
	},
	{
		item: 'Stories and Ink Aftercare Foam Cleanser',
		stockLevel: 90,
		quantity: '20',
		category: 'Aftercare',
		location: 'Stockroom',
		premises: 'Studio',
	},
	{
		item: 'Rosa After Care Balm 10ml',
		stockLevel: 50,
		quantity: '25',
		category: 'Aftercare',
		location: 'Stockroom',
		premises: 'Studio',
	},
	{
		item: 'Dynamic Black Ink (1oz)',
		stockLevel: 60,
		quantity: '30',
		category: 'Cartridges',
		location: 'Ink cabinet',
		premises: 'Studio',
	  },
	  {
		item: 'FK Irons Cartridge Needles (Box of 20)',
		stockLevel: 40,
		quantity: '10',
		category: 'Cartridges',
		location: 'Needle drawer',
		premises: 'Studio',
	  },
	  {
		item: 'Spirit Classic Transfer Paper (Box of 100)',
		stockLevel: 80,
		quantity: '40',
		category: 'Transfer Paper',
		location: 'Paper shelf',
		premises: 'Studio',
	  },
	  {
		item: 'Electrum Eco Stencil Remover',
		stockLevel: 25,
		quantity: '10',
		category: 'Miscellaneous',
		location: 'Supply closet',
		premises: 'Studio',
	  },
	  {
		item: 'Green Soap (Gallon)',
		stockLevel: 15,
		quantity: '5',
		category: 'Miscellaneous',
		location: 'Cleaning supplies area',
		premises: 'Studio',
	  },
	  {
		item: 'Yayo Tattoo Butter',
		stockLevel: 100,
		quantity: '20',
		category: 'Aftercare',
		location: 'Reception cupboard',
		premises: 'Studio',
	  },
	  {
		item: 'Redemption Tattoo Care (1oz)',
		stockLevel: 90,
		quantity: '40',
		category: 'Aftercare',
		location: 'Stockroom',
		premises: 'Studio',
	  },
	  {
		item: 'Cheyenne HAWK Cartridges Liner 0.30',
		stockLevel: 120,
		quantity: '60',
		category: 'Cartridges',
		location: 'Needle drawer',
		premises: 'Studio',
	  },
	  {
		item: 'Aloe Tattoo Cleansing Foam',
		stockLevel: 70,
		quantity: '25',
		category: 'Aftercare',
		location: 'Reception cupboard',
		premises: 'Studio',
	  },
	  {
		item: 'Kuro Sumi Transfer Stencil Primer',
		stockLevel: 55,
		quantity: '15',
		category: 'Transfer Paper',
		location: 'Paper shelf',
		premises: 'Studio',
	  },
];
export default stockTableData;