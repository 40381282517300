// Imports
import React, { useState, forwardRef } from 'react';
import {
  Flex,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
  InputProps,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Define the props interface
interface DateFieldProps extends InputProps {
  id?: string;
  label?: string;
  extra?: React.ReactNode;
  placeholder?: string;
  mb?: string | number;
}

const DateField: React.FC<DateFieldProps> = (props) => {
  const { id, label, extra, placeholder, mb, ...rest } = props;

  // State to manage selected date
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  // Custom Input Component using forwardRef
  const CustomInput = forwardRef<HTMLInputElement, any>(
    ({ value, onClick }, ref) => (
      <Input
        onClick={onClick}
        ref={ref}
        value={value}
        placeholder={placeholder}
        readOnly
        {...rest}
      />
    )
  );

  // Set display name for debugging purposes
  CustomInput.displayName = 'CustomInput';

  return (
    <Flex direction='column' mb={mb ? mb : '30px'}>
      <FormLabel
        display="flex"
        ms="10px"
        htmlFor={id}
        fontSize="sm"
        color={textColorPrimary}
        fontWeight="bold"
        _hover={{ cursor: 'pointer' }}
      >
        {label}
        {extra && (
          <Text fontSize="sm" fontWeight="400" ms="2px">
            {extra}
          </Text>
        )}
      </FormLabel>
      <DatePicker
	  	fontWeight='500'
		variant='main'
		h='44px'
		maxH='44px'
		showMonthYearPicker
		dateFormat="MM/yyyy"
        id={id}
        selected={selectedDate}
        onChange={(date: Date | null) => setSelectedDate(date)}
        customInput={<CustomInput />}
      />
    </Flex>
  );
};

export default DateField;
