import {SimpleGrid } from "@chakra-ui/react";
import ItemTable from "components/dataDisplay/ItemTable";
import stockTableData from "./data/stockTableData";
import InventoryHeader from "./components/inventoryHeader";

export default function AllItems() {
	// Chakra Color Mode
	return (
			<SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
				<InventoryHeader />
				<ItemTable tableData={stockTableData} tableName="Inventory" />
			</SimpleGrid>
	);
}