import { Box, SimpleGrid, Flex, Icon, Text, Image, useColorModeValue } from "@chakra-ui/react";
import IconBox from 'components/icons/IconBox';
import { RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";
import { MdOutlineBarChart, MdCompareArrows, MdShoppingBag } from "react-icons/md";
import Statistics from "components/dataDisplay/MiniStatistics";
import FakeBarChart from 'assets/img/account/FakeBarChart.png';


export default function InventoryHeader() {
    const iconBg = useColorModeValue('secondaryGray.300', 'navy.700');
    const iconColor = useColorModeValue('brand.500', 'white');
    // Chakra Color Mode
    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <SimpleGrid
          columns={{ sm: 1, md: 2, lg: 2, xl: 2, '2xl': 4 }}
          gap="20px"
          mb="20px"
        >
          <Flex>
            <Statistics
              focused={true}
              bgGradient="linear(to-b, brand.400, brand.600)"
              title={'Total Inventory Value'}
              value="£6,347"
              detail={
                <Flex align="center">
                  <Icon as={RiArrowUpSFill} color="white" />
                  <Text color="white" fontSize="sm" mx="3px" fontWeight="500">
                    +20%
                  </Text>
                  <Text color="white" fontSize="sm" fontWeight="500">
                    Since last month
                  </Text>
                </Flex>
              }
              illustration={
                <IconBox
                  w="80px"
                  h="80px"
                  //   bg="linear-gradient(290.56deg, #868CFF -18.35%, #4318FF 60.45%)"
                  bgGradient="linear(to-b, brand.600, brand.400)"
                  icon={
                    <Icon
                      as={MdOutlineBarChart}
                      w="38px"
                      h="38px"
                      color="white"
                    />
                  }
                />
              }
            />
          </Flex>
          <Flex>
            <Statistics
              title={'Sales'}
              value="£1,249"
              detail={
                <Flex align="center">
                  <Icon as={RiArrowDownSFill} color="red.500" />
                  <Text color="red.500" fontSize="sm" mx="4px" fontWeight="700">
                    -12%
                  </Text>
                  <Text
                    color="secondaryGray.600"
                    fontSize="sm"
                    fontWeight="500"
                  >
                    Since last month
                  </Text>
                </Flex>
              }
              illustration={<Image src={FakeBarChart} />}
            />
          </Flex>
          <Flex>
            <Statistics
              title={'Items Consumed'}
              value="50"
              detail={
                <Flex align="center">
                  <Icon as={RiArrowUpSFill} color="green.500" />
                  <Text
                    color="green.500"
                    fontSize="sm"
                    mx="4px"
                    fontWeight="700"
                  >
                    +16%
                  </Text>
                  <Text
                    color="secondaryGray.600"
                    fontSize="sm"
                    fontWeight="500"
                  >
                    Since last month
                  </Text>
                </Flex>
              }
              illustration={
                <IconBox
                  w="80px"
                  h="80px"
                  bg={iconBg}
                  icon={
                    <Icon color={iconColor} as={MdCompareArrows} w="38px" h="38px" />
                  }
                />
              }
            />
          </Flex>
          <Flex>
            <Statistics
              title={'Total Items'}
              value="670"
              detail={
                <Flex align="center">
                  <Icon as={RiArrowUpSFill} color="green.500" />
                  <Text
                    color="green.500"
                    fontSize="sm"
                    mx="4px"
                    fontWeight="700"
                  >
                    +27%
                  </Text>
                  <Text
                    color="secondaryGray.600"
                    fontSize="sm"
                    fontWeight="500"
                  >
                    Since last month
                  </Text>
                </Flex>
              }
              illustration={
                <IconBox
                  w="80px"
                  h="80px"
                  bg={iconBg}
                  icon={
                    <Icon color={iconColor} as={MdShoppingBag} w="28px" h="28px" />
                  }
                />
              }
            />
          </Flex>
        </SimpleGrid>
        </Box>
    );
}