// Chakra imports
import { Button } from '@chakra-ui/react';

export default function TableCRUDActions(props: {
	icon?: JSX.Element;
	name?: string;
	action: () => void | any;
	actionName?: string;
	[x: string]: any;
}) {
	const { icon, action, } = props;

	return (
			<Button variant='brand' px='12px' onClick={action} fontSize='sm' fontWeight='700'>
				{icon}
			</Button>
	);
}
