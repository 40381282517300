import { Text, Box, Flex, SimpleGrid, Button } from "@chakra-ui/react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import IncomingOrder from "components/dataDisplay/IncomingOrder";
import React from "react";

type Movement = {
  date: string;
  status: string;
  name: string;
  supplier: string;
  orderDocumentLink: string;
};

  
const allMovements: Movement[] = [
  { date: "Today", status: "due", name: "ABCD1234", supplier: "Amazon", orderDocumentLink: "www.google.com" },
  { date: "Today", status: "due", name: "GRPK8294", supplier: "Barber DTS", orderDocumentLink: "www.google.com" },
  { date: "Yesterday", status: "overdue", name: "XQNB4102", supplier: "KillerInk", orderDocumentLink: "www.google.com" },
  { date: "Yesterday", status: "arrived", name: "UJYZ5698", supplier: "Jungle Tattoo Supplies", orderDocumentLink: "www.google.com" },
  { date: "Yesterday", status: "arrived", name: "TLAH0921", supplier: "Amazon", orderDocumentLink: "www.google.com" },
  { date: "Yesterday", status: "cancelled", name: "CQBM7735", supplier: "Amazon", orderDocumentLink: "www.google.com" },
  { date: "Two days ago", status: "arrived", name: "MZRA5123", supplier: "Cartridge Masters", orderDocumentLink: "www.google.com" },
  { date: "Two days ago", status: "arrived", name: "KFRT8391", supplier: "PaperPrint Pros", orderDocumentLink: "www.google.com" },
  { date: "Three days ago", status: "arrived", name: "WQYT4420", supplier: "Dermacare Essentials", orderDocumentLink: "www.google.com" },
  { date: "Three days ago", status: "overdue", name: "PJAX7719", supplier: "InkFlow Supplies", orderDocumentLink: "www.google.com" },
  { date: "Last Week", status: "cancelled", name: "BGRT9021", supplier: "Mix&Match Supplies", orderDocumentLink: "www.google.com" },
  { date: "Last Week", status: "overdue", name: "ZFKR3147", supplier: "StudioFurnish", orderDocumentLink: "www.google.com" },
];

export default function OrderHistoryTable() {


  
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, ] = React.useState(10); 
  const [globalFilter, setGlobalFilter] = React.useState(""); 
  const [filteredMovements, setFilteredMovements] = React.useState<Movement[]>(allMovements);

  const start = pageIndex * pageSize;
  const end = start + pageSize;
  const pageData = filteredMovements.slice(start, end);
  const pageCount = Math.ceil(filteredMovements.length / pageSize);
  React.useEffect(() => {
    const filtered = allMovements.filter((movement) =>
      movement.name.toLowerCase().includes(globalFilter.toLowerCase()) ||
    movement.supplier.toLowerCase().includes(globalFilter.toLowerCase())
    );
    setFilteredMovements(filtered);
    setPageIndex(0);
  }, [globalFilter]);


    return (
      <Box pt={{ base: "130px", md: "40px", xl: "40px" }}>
    {/* Search bar to filter the stock movements */}
    <Box mb="10px">
      <SearchBar
        value={globalFilter}
        onChange={(e: any) => setGlobalFilter(e.target.value)}
        h="44px"
        w={{ lg: "390px" }}
        borderRadius="16px"
        placeholder="Search by name or reason..."
      />
    </Box>

    <SimpleGrid
      mb="20px"
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}
    >
      {pageData.map((movement, index) => (
        <IncomingOrder
          key={index}
          date={movement.date}
          status={movement.status}
          name={movement.name}
          reason={movement.supplier}
          orderDocumentLink={movement.orderDocumentLink}
        />
      ))}
    </SimpleGrid>

    {/* Pagination Controls */}
    <Flex justify="space-between" align="center">
      <Text>
        Showing {filteredMovements.length === 0 ? 0 : start + 1} to{" "}
        {end > filteredMovements.length ? filteredMovements.length : end} of{" "}
        {filteredMovements.length} entries
      </Text>

      <Flex gap="10px">
        <Button
          onClick={() => setPageIndex(pageIndex - 1)}
          disabled={pageIndex === 0}
        >
          Prev
        </Button>
        <Button
          onClick={() => setPageIndex(pageIndex + 1)}
          disabled={pageIndex >= pageCount - 1 || pageCount === 0}
        >
          Next
        </Button>
      </Flex>
    </Flex>
  </Box>
    )
    
}