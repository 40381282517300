// Chakra imports
import {
  Flex,
  Image,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import fakeGraph from 'assets/img/dashboards/fakeGraph.png';
import {
  MdDomain,
  MdElectricCar,
  MdSchool,
} from 'react-icons/md';
import SlowConsumedItem from 'components/dataDisplay/SlowConsumedItem';

export default function DaysInventoryOutstanding(props: { [x: string]: any }) {
  const { ...rest } = props;
  const blueIcon = useColorModeValue('blue.500', 'white');
  const greenIcon = useColorModeValue('green.500', 'white');
  const yellowIcon = useColorModeValue('yellow.500', 'white');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  return (
    <Card flexDirection="column" w="100%" {...rest}>
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex flexDirection="column" me="20px">
            <Text color="white" fontSize="sm" fontWeight="500">
              Days Inventory Outstanding
            </Text>
            <Text
              color="white"
              fontSize="34px"
              fontWeight="700"
              lineHeight="100%"
            >
              5
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            ms="auto"
            justify="space-between"
            align="flex-end"
          >
            <Image src={fakeGraph} w="59px" h="17px" />
          </Flex>
        </Flex>
      </Flex>
      <Text color="secondaryGray.600" fontWeight="500" fontSize="sm" mb="10px">
        Slowest Consumed Items
      </Text>
      <Flex direction="column">
        <SlowConsumedItem
          mb="20px"
          name="TKTX Gold"
          sum="7"
          icon={<Icon as={MdDomain} color={blueIcon} w="20px" h="18px" />}
        />
        <SlowConsumedItem
          mb="20px"
          name="Mad Rabbit Tattoo Balm"
          sum="5"
          icon={<Icon as={MdElectricCar} color={greenIcon} w="20px" h="18px" />}
        />
        <SlowConsumedItem
          name="Yayo Clear Tattoo Butter"
          sum="4"
          icon={<Icon as={MdSchool} color={yellowIcon} w="20px" h="18px" />}
        />
      </Flex>
    </Card>
  );
}
