import {
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import Card from 'components/card/Card';
import LowStockItem from 'components/dataDisplay/LowStock';

import {
  MdOutlineBrush,
  MdDraw,
  MdMiscellaneousServices
} from 'react-icons/md';

import ExpiringItem from 'components/dataDisplay/ExpiringStock';

export default function LowStock(props: { [x: string]: any }) {
  const { ...rest } = props;

  const iconColor = useColorModeValue('brand.500', 'white');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  return (
    <Card {...rest}>
      <Flex justify="space-between" px="10px" pt="5px" mb="25px" align="center">
        <Text
          color={textColor}
          fontSize="lg"
          fontWeight="700"
          lineHeight="100%"
        >
          Low Stock Items
        </Text>
      </Flex>
      <LowStockItem
        mb="26px"
        name="Kwadron 14RSLT"
        sum="5"
        icon={
          <Icon
            as={MdDraw}
            color={iconColor}
            w="20px"
            h="18px"
          />
        }
      />
      <LowStockItem
        mb="26px"
        name="Black Disposable Gloves Large"
        date="18 September 2022"
        sum="7"
        icon={
          <Icon
            as={MdMiscellaneousServices}
            color={iconColor}
            w="20px"
            h="18px"
          />
        }
      />
      <LowStockItem
        mb="26px"
        name="Eternal Ink Midnight Blue"
        date="15 September 2022"
        sum="2"
        icon={
          <Icon
            as={MdOutlineBrush}
            color={iconColor}
            w="20px"
            h="18px"
          />
        }
      />
      <Flex justify="space-between" px="10px" pt="5px" mb="25px" align="center">
      <Text
          color={textColor}
          fontSize="lg"
          fontWeight="700"
          lineHeight="100%"
        >
          Expiring Items
        </Text>
        </Flex>
        <ExpiringItem
        mb="26px"
        name="Eternal Ink Midnight Blue"
        date="15 September 2022"
        sum="2"
        icon={
          <Icon
            as={MdOutlineBrush}
            color={iconColor}
            w="20px"
            h="18px"
          />
        }
      />
    </Card>
  );
}
