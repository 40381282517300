import { Box } from "@chakra-ui/react";
import OrderHistoryTable from "./components/orderHistoryTable";
import OrderHistoryHeader from "./components/orderHistoryHeader";

export default function OrdersHistory() {
  return (
  <Box mb='20px'>
    <OrderHistoryHeader />
    <OrderHistoryTable />
  </Box>
  );
}