// Chakra imports
import { Flex, Button } from '@chakra-ui/react';

type QuickButtonProps = {
	name: string;
	action: () => void;
	icon?: JSX.Element
}

export default function QuickButton({ name, action, icon }: QuickButtonProps) {

	return (
		<Flex justifyContent='center' alignItems='center' w='100%'>
			<Button variant='action' leftIcon={icon} px='24px' onClick={action} fontSize='sm' fontWeight='700'>
				{name}
			</Button>
		</Flex>
	);
}
