

type RowObj = {
	item: string;
	category: string;
	quantity: string;
	stockLevel: number;
	location: string;
	premises: string;
};

const stockTableData: RowObj[] = [
	{
		item: 'Kwadron 35/11SEMLT',
		stockLevel: 100,
		quantity: '20',
		category: 'Cartridges',
		location: 'Stockroom',
		premises: 'Office',
	},
	{
		item: 'A4 Transfer Sheets',
		stockLevel: 75,
		quantity: '50',
		category: 'Transfer Paper',
		location: 'Stockroom',
		premises: 'Office',
	},
	{
		item: 'Plastic Cups',
		stockLevel: 90,
		quantity: '20',
		category: 'Miscellaneous',
		location: 'Stockroom',
		premises: 'Office',
	},
	{
		item: 'Ink Caps',
		stockLevel: 50,
		quantity: '25',
		category: 'Miscellaneous',
		location: 'Stockroom',
		premises: 'Office',
	},
	{
		item: 'Cheyenne Safety Cartridges (5RL)',
		stockLevel: 60,
		quantity: '30',
		category: 'Cartridges',
		location: 'Ink cabinet',
		premises: 'Office',
	  },
	  {
		item: 'Spirit Classic Transfer Paper (A3)',
		stockLevel: 40,
		quantity: '15',
		category: 'Transfer Paper',
		location: 'Paper shelf',
		premises: 'Office',
	  },
	  {
		item: 'Green Soap (1L)',
		stockLevel: 25,
		quantity: '10',
		category: 'Miscellaneous',
		location: 'Cleaning supplies area',
		premises: 'Office',
	  },
	  {
		item: 'Cohesive Bandage Rolls',
		stockLevel: 45,
		quantity: '20',
		category: 'Miscellaneous',
		location: 'Stockroom',
		premises: 'Office',
	  },
	  {
		item: 'Barrier Film Roll',
		stockLevel: 80,
		quantity: '40',
		category: 'Miscellaneous',
		location: 'Supply closet',
		premises: 'Office',
	  },
	  {
		item: 'Aftercare Foam Cleanser',
		stockLevel: 90,
		quantity: '35',
		category: 'Aftercare',
		location: 'Reception cupboard',
		premises: 'Office',
	  },
	  {
		item: 'Nitrile Gloves (Box of 100)',
		stockLevel: 120,
		quantity: '60',
		category: 'Miscellaneous',
		location: 'Supply closet',
		premises: 'Office',
	  },
	  {
		item: 'Vaseline Jelly (250g)',
		stockLevel: 65,
		quantity: '30',
		category: 'Aftercare',
		location: 'Reception cupboard',
		premises: 'Office',
	  },
	  {
		item: 'Dynamic White Ink (1oz)',
		stockLevel: 50,
		quantity: '25',
		category: 'Cartridges',
		location: 'Ink cabinet',
		premises: 'Office',
	  },
	  {
		item: 'Cheyenne Sol Nova Pen Stand',
		stockLevel: 10,
		quantity: '5',
		category: 'Miscellaneous',
		location: 'Equipment storage',
		premises: 'Office',
	  },
	  {
		item: 'Disposable Razor Packs',
		stockLevel: 85,
		quantity: '40',
		category: 'Miscellaneous',
		location: 'Stockroom',
		premises: 'Office',
	  },
	  {
		item: 'Rosa After Care Balm (30ml)',
		stockLevel: 95,
		quantity: '45',
		category: 'Aftercare',
		location: 'Reception cupboard',
		premises: 'Office',
	  },
	  {
		item: 'Inkjecta Cartridge Grips',
		stockLevel: 30,
		quantity: '10',
		category: 'Cartridges',
		location: 'Needle drawer',
		premises: 'Office',
	  },
	  {
		item: 'Stencil Transfer Spray',
		stockLevel: 70,
		quantity: '30',
		category: 'Transfer Paper',
		location: 'Paper shelf',
		premises: 'Office',
	  },
	  {
		item: 'Stencil Remover Wipes',
		stockLevel: 60,
		quantity: '20',
		category: 'Transfer Paper',
		location: 'Paper shelf',
		premises: 'Office',
	  },
	  {
		item: 'Mast Tattoo Machine Kits',
		stockLevel: 15,
		quantity: '5',
		category: 'Miscellaneous',
		location: 'Equipment storage',
		premises: 'Office',
	  },
];
export default stockTableData;