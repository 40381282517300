import { Box, SimpleGrid } from "@chakra-ui/react";
import TableCRUDActions from "components/actions/TableCRUDActions";
import { MdCloudDownload } from "react-icons/md";
import SelectField from "components/fields/SelectField";

export default function BulkActions() {
	// Chakra Color Mode
	return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <SelectField id="bulkActions" label="Export type" mb="20px" placeholder="All inventory items">
            <option>All inventory items</option>
            <option>In stock inventory items</option>
            <option>Out of stock inventory items</option>
            <option>Categories</option>

        </SelectField>
        <SimpleGrid alignItems='Center' mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
            <TableCRUDActions 
            name="Export to CSV" 
            icon={<MdCloudDownload/>}
            action={() => console.log('succesful action')}
            actionName="Export"
            />
            CSV
        </SimpleGrid>
      </Box>
	);
}