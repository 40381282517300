import { Icon } from '@chakra-ui/react';
import {
  MdDashboard,
  MdHome,
  MdLock,
} from 'react-icons/md';

// Dashboard Imports

import DashboardsDefault from 'views/admin/dashboards/default';
import DashboardsAnalytics from 'views/admin/dashboards/analytics';

// Inventory Imports

import AllItems from 'views/admin/inventory/allitems';
import AddItem from 'views/admin/inventory/additem';
import Categories from 'views/admin/inventory/categories';
import BulkActionsPage from 'views/admin/inventory/bulkactions';

// Stock Import

import StockLevels from 'views/admin/stock/stocklevels';
import StockMovements from 'views/admin/stock/movements';

// Users Imports

import NewUser from 'views/admin/users/addnew';
import AllUsers from 'views/admin/users/allusers';

// Orders Imports

import OrdersHistory from 'views/admin/orders/history';
import SuppliersPage from 'views/admin/orders/suppliers';


const routes = [
  // --- Dashboards ---
  {
    name: 'Dashboards',
    path: '/dashboards',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Overview',
        layout: '/admin',
        path: '/dashboards/default',
        component: <DashboardsDefault />,
      },
      {
        name: 'Analytics',
        layout: '/admin',
        path: '/dashboards/analytics',
        component: <DashboardsAnalytics />,
      },
    ],
  },  
  // // --- Inventory Pages ---
  {
    name: 'Inventory',
    path: '/inventory',
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'All items',
        path: '/inventory/all',
        component: <AllItems />,
        layout: '/admin',
      },
      {
        name: 'Add New Item',
        path: '/inventory/add',
        component: <AddItem />,
        layout: '/admin',
      },
      {
        name: 'Categories',
        path: '/inventory/categories',
        component: <Categories />,
        layout: '/admin'
      },
      {
        name: 'Bulk Actions',
        path: '/inventory/bulk',
        component: <BulkActionsPage />,
        layout: '/admin'
      }
    ],
  },
  // // --- Stock Management 
  {
    name: 'Stock Management',
    path: '/stock',
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Stock Levels',
        path: '/stock/levels',
        component: <StockLevels />,
        layout: '/admin',
      },
      {
        name: 'Stock Movements',
        path: '/stock/movement',
        component: <StockMovements />,
        layout: '/admin',
      }
    ],
  },
  // // --- Orders 
  {
    name: 'Orders',
    path: '/orders',
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Order History',
        path: '/orders/history',
        component: <OrdersHistory />,
        layout: '/admin',
      },
      {
        name: 'Suppliers',
        path: '/orders/suppliers',
        component: <SuppliersPage />,
        layout: '/admin',
      }
    ],
  },
  // // --- Users
  {
    name: 'Users',
    path: '/users',
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'All users',
        path: '/users/all',
        component: <AllUsers />,
        layout: '/admin',
      },
      {
        name: 'Add New User',
        path: '/users/add',
        component: <NewUser />,
        layout: '/admin',
      }
    ],
  },
    // // --- Settings
    {
      name: 'Settings',
      path: '/settings',
      icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Business Settings',
          path: '/settings/business',
          component: <AllUsers />,
          layout: '/admin',
        },
        {
          name: 'Notifications',
          path: '/settings/notifications',
          component: <AllUsers />,
          layout: '/admin',
        },
        {
          name: 'My Profile',
          path: '/settings/me',
          component: <AllUsers />,
          layout: '/admin',
        },
        {
          name: 'Audit',
          path: '/settings/audit',
          component: <AllUsers />,
          layout: '/admin'
        }
      ],
    },
    // // --- Help
    {
      name: 'Help & Support',
      path: '/help',
      icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Documentation',
          path: '/help/docs',
          component: <AllUsers />,
          layout: '/admin',
        },
        {
          name: 'Contact Support',
          path: '/help/support',
          component: <AllUsers />,
          layout: '/admin',
        },
        {
          name: 'Feedback',
          path: '/help/feedback',
          component: <AllUsers />,
          layout: '/admin',
        }
      ],
    },
  // --- Authentication ---
  {
    name: 'Logout',
    path: '/auth/logout',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    collapse: false,
  },
];

export default routes;
