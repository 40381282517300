// Chakra imports
import {
    Flex,
    Box,
    Icon,
    Text,
  } from '@chakra-ui/react';
import Card from 'components/card/Card';
import {
    overallYearlyUsage,
    overallYearlyUsageOptions,
  } from 'variables/charts';
import { RiArrowUpSFill } from 'react-icons/ri';
import LineAreaChart from 'components/charts/LineAreaChart';
  
  export default function OverallYearlyUsage(props: { [x: string]: any }) {
    const { ...rest } = props;
    const newOptions = {
      ...overallYearlyUsageOptions,
      colors: ['var(--chakra-colors-brand-500)', '#39B8FF'],
    };
    return (
      <Card
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        w="100%"
        mb={{ base: '20px', lg: '0px' }}
        {...rest}
      >
        <Flex justify="space-between" px="20px" pt="5px" w="100%">
          <Flex align="center" w="100%">
            <Flex flexDirection="column" me="20px">
              <Text
                color="secondaryGray.600"
                fontSize="sm"
                fontWeight="500"
                mt="4px"
              >
                YoY Usage
              </Text>
            </Flex>
            <Flex align="center">
              <Icon as={RiArrowUpSFill} color="green.500" me="2px" />
              <Text color="green.500" fontSize="sm" fontWeight="700">
                +20.45%
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box minH="260px" mt="auto" w="100%">
          <LineAreaChart
            chartData={overallYearlyUsage}
            chartOptions={newOptions}
          />
        </Box>
      </Card>
    );
  }
  