
type RowObj = {
    name: string;
    category: string;
    contactEmail: string;
    contactPhone: string;
    location: string;
  };
  

const supplierTableData: RowObj[] = [
    {
        name: "InkFlow Supplies",
        category: "Tattoo Ink",
        contactEmail: "contact@inkflowsupplies.com",
        contactPhone: "+1 555-123-4567",
        location: "New York, USA",
      },
      {
        name: "Dermacare Essentials",
        category: "Aftercare",
        contactEmail: "support@dermacareessentials.io",
        contactPhone: "+1 555-234-5678",
        location: "Los Angeles, USA",
      },
      {
        name: "Cartridge Masters",
        category: "Cartridges",
        contactEmail: "hello@cartridgemasters.co",
        contactPhone: "+1 555-345-6789",
        location: "Chicago, USA",
      },
      {
        name: "PaperPrint Pros",
        category: "Transfer Paper",
        contactEmail: "orders@paperprintpros.com",
        contactPhone: "+1 555-456-7890",
        location: "Houston, USA",
      },
      {
        name: "Mix&Match Supplies",
        category: "Miscellaneous",
        contactEmail: "info@mixandmatchsupplies.net",
        contactPhone: "+1 555-567-8901",
        location: "San Francisco, USA",
      },
      {
        name: "NeedleNexus",
        category: "Needles",
        contactEmail: "sales@needlenexus.com",
        contactPhone: "+1 555-678-9012",
        location: "Toronto, Canada",
      },
      {
        name: "GloveGuard",
        category: "Sterilization Supplies",
        contactEmail: "custcare@gloveguard.com",
        contactPhone: "+1 555-789-0123",
        location: "Vancouver, Canada",
      },
      {
        name: "Consumable Connect",
        category: "Sterilization Supplies",
        contactEmail: "contact@consumableconnect.io",
        contactPhone: "+1 555-890-1234",
        location: "London, UK",
      },
      {
        name: "SterilizeSafe",
        category: "Sterilization Supplies",
        contactEmail: "support@sterilizesafe.com",
        contactPhone: "+44 20 5550 6789",
        location: "Manchester, UK",
      },
      {
        name: "StudioFurnish",
        category: "Furniture",
        contactEmail: "hello@studiofurnish.co.uk",
        contactPhone: "+44 20 5551 2345",
        location: "Berlin, Germany",
      },
];
export default supplierTableData;