import { Box } from "@chakra-ui/react";
import StockMovementsTable from "./components/stockMovementTable";

export default function StockMovements() {
 
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <StockMovementsTable />
    </Box>
  );
}
