// Chakra imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import IconBox from 'components/icons/IconBox';

export default function LowStockItem(props: {
	sum: string;
	icon: JSX.Element;
	name: string;
	[x: string]: any;
}) {
	const { date, sum, icon, name, ...rest } = props;

	const textColor = useColorModeValue('secondaryGray.900', 'red.400');
	const iconBoxBg = useColorModeValue('secondaryGray.300', 'red.400');
	return (
		<Flex justifyContent='center' alignItems='center' w='100%' {...rest}>
			<IconBox h='42px' w='42px' bg={iconBoxBg} me='20px' icon={icon} />
			<Flex direction='column' align='start' me='auto'>
				<Text color={textColor} fontSize='md' me='6px' fontWeight='700'>
					{name}
				</Text>
			</Flex>
			<Text ms='auto' color={textColor} fontSize='sm' me='6px' fontWeight='700'>
				{sum}
			</Text>
		</Flex>
	);
}
